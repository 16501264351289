
export default function SaasboxNav() {
	return (
		<div className="navbar-nav navbar-nav-scroll" dir="ltr">
			<a className="nav-link" href="/#home">
				<b>
					About Us
				</b>
			</a>
			<a className="nav-link" href="/#our-project">
				<b>
					Our Project
				</b>
			</a>
			<a className="nav-link" href="/#pricing">
				<b>
					Pricing
				</b>
			</a>
			<a className="nav-link" href="/#contact-us">
				<b>
					Contact
				</b>
			</a>
		</div>
	);
}
