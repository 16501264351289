export default function SinglePricePlanThree(props) {
	const {
		activePlan,
		planTitle,
		price,
		priceText,
		pricingDesc,
		inputID,
		btnText,
		backgroundImage,
	} = props

	const ulList = pricingDesc.map((ele, index) => (
		<li
			key={index}
			className={`availability-${ele.availability}`}
		>
			{ele.text}
		</li>
	))

	return (
		<div className='col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4'>
			<div
				className={`card pricing-card ${activePlan}`}
				style={{
					backgroundImage: `url(${process.env.PUBLIC_URL}/${backgroundImage})`,
				}}
			>
				<div className='pricing-heading'>
					<div className='price'>
						<h6>{planTitle}</h6>
						<h1
							className='mb-0 display-5 fw-bold '
							style={{ color: "#212529" }}
						>
							{price}
						</h1>
						<span className='fz-12 d-block'>{priceText}</span>
					</div>
				</div>
				<div className='pricing-desc my-5'>
					<ul className='ps-0 mb-0 list-unstyled'>{ulList}</ul>
				</div>
				<a
					className='nav-link'
					href='/#contact-us'
				>
					<div className='pricing-btn'>
						<label
							className={`btn btn-sm rounded-pill`}
							style={{ backgroundColor: "#212529", color: "#fff" }}
							for={inputID}
						>
							{btnText}

							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								fill='currentColor'
								class='bi bi-arrow-left'
								viewBox='0 0 16 16'
							>
								<path
									fill-rule='evenodd'
									d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'
								/>
							</svg>
						</label>
					</div>
				</a>
			</div>
		</div>
	)
}
