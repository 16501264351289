import "tiny-slider/dist/tiny-slider.css"

import heroImg from "../../../assets/images/photo-02.jpg"

const HeroData = [
	{
		id: 1,
		catagory: ["Powerful", "Easy to use", "Free"],
		title: "Easy Landing Australia",
		subtitle: `אחרי המון מחשבה כיצד אנחנו יכולים לעזור לכם לעשות נחיתה רכה במדינה הכי מטורפת על הכדור! 
        החלטנו להקים מיזם שבו נלווה אתכם בכל הקשור בהגעה לאוסטרליה. להעביר את החיים שלך למדינה חדשה ושונה יכול להתקיל ולהלחיץ, תפקידנו להפיג את החשש ולהיות זמינים עבורך 24/7 לכל שאלה.`,
		btnGroup: [
			{
				btnColor: "btn-warning",
				btnUrl: "https://wa.me/61468917354?text=",
				btnText: "WA",
			},
			{
				btnColor: "btn-warning",
				btnUrl: "https://www.instagram.com/easy_landing_australia/",
				btnText: "IG",
			},
		],
		image: heroImg,
	},
]

export default function HeroThree() {
	const heroSlideThree = HeroData.map((ele, index) => (
		<div
			key={index}
			className='welcome3-slide-item  bg-white '
		>
			<div className='container h-100'>
				<div
					className='row g-lg-5 h-100 align-items-center'
					dir='ltr'
				>
					{/* Welcome Content */}

					<div className='col-12 col-sm-10 col-md-6'>
						<div
							className='welcome-content'
							dir='rtl'
						>
							<h2
								className=''
								style={{ color: "#495057" }}
							>
								About Us
							</h2>
							<p
								className='mb-3'
								style={{ color: "#495057" }}
							>
								ברוכים הבאים לתהליך שיעזור לכם בהגעה לאוסטרליה.
							</p>

							<p
								className='mb-3'
								style={{ color: "#495057" }}
							>
								אחרי המון מחשבה כיצד אנחנו יכולים לעזור לכם לעשות נחיתה רכה
								במדינה הכי מטורפת על הכדור!
								<br />
								תהליך ליווי וייעוץ בכל הקשור לנושאים שחשובים עם הנחיתה
								באוסטרליה, שמטרתו לחסוך לכם את ההתעסקות, את הכסף והכי חשוב את
								הזמן ששווה פה הרבה כסף!
							</p>
							<p
								className='mb-3'
								style={{ color: "#495057" }}
							>
								התהליך כולל הגשת בקשה לויזה, מציאת טיסות במחירים משתלמים,
								וביטוחים במדינה זרה. בנוסף כיצד להתחיל את החיים באוסטרליה אם זה
								בפתיחת חשבון בנק וכרטיס סים לסלולארי.
								<br />
								<br />
								מה חשוב לדעת לפני שקונים רכב? ואיך מעבירים בעלות. ובנוסף נסייע
								לכם בפאן המקצועי ! כיצד מוצאים עבודה ואילו כרטיסי עבודה נדרשים
								בכל מקצוע, ובנוסף הכנת קורות חיים שמתאימים לעבודה באוסטרליה.
							</p>
							<b
								className='mb-5'
								style={{ color: "#495057" }}
							>
								יש עוד המון טיפים שמחכים לכם איתנו בתהליך, אז למה אתם מחכים?
								שלחו הודעה ותקבלו את כל הפרטים עוד היום!
							</b>
							{/* 				
							<div className='btn-group-two d-flex align-items-center justify-content-center m-5'>
								<a
									className={` d-flex align-items-center rounded border border-dark `}
									href={ele.btnGroup[0].btnUrl}
									style={{
										paddingTop: "10px",
										paddingBottom: "10px",
										paddingLeft: "15px",
										paddingRight: "15px",
									}}
								>
									<span
										style={{
											color: "#343A40",
											marginLeft: "10px",
										}}
									>
										WhatsApp
									</span>
									<i
										className='bi bi-whatsapp '
										style={{
											fontSize: "1.6rem",
											color: "#343A40",
										}}
									></i>
								</a>
								<a
									className={`me-3 d-flex align-items-center rounded border border-dark`}
									href={ele.btnGroup[1].btnUrl}
									style={{
										paddingTop: "10px",
										paddingBottom: "10px",
										paddingLeft: "15px",
										paddingRight: "15px",
									}}
								>
									<span
										style={{
											color: "#343A40",
											marginLeft: "10px",
										}}
									>
										Instagram
									</span>
									<i
										className='bi bi-instagram'
										style={{
											fontSize: "1.6rem",
											color: "#343A40",
										}}
									></i>
								</a>
							</div> */}
						</div>
					</div>

					{/* Welcome Thumb */}

					<div className='col-12 col-sm-8 col-md-6'>
						<div className='welcome-thumb ms-xl-5'>
							<img
								src={heroImg}
								alt='HeroImage'
								className='rounded-3 d-none d-sm-block'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	))

	return (
		<div
			className='welcome-area hero3'
			id='home'
		>
			{heroSlideThree}

			{/* <ScrollAnimation
				animateIn="fadeInUp"
				animateOut="fadeInOut"
				delay={100}
				animateOnce={true}
			>
				Slide Wrap
				<div className="welcome3-slide-wrap">
					<TinySlider settings={settings}>
					</TinySlider>
				</div>
			</ScrollAnimation> */}
		</div>
	)
}
