import Accordion from "react-bootstrap/Accordion";
import ContactForm from "./ContactForm";

export default function FAQOne(props) {
	const { heading } = props;

	return (
		<div className="faq--area" id="contact-us" >
			<div className="container ">
				<div className="row g-4 g-lg-5 align-items-start">
					<div className="col-12 col-md-7 col-lg-6">
						<div className="faq-content">
							<h2 className="mb-5">{heading}</h2>

							<Accordion
								className="faq--accordian"
								defaultActiveKey=""
							>
								{/* Accordion Item */}
								<Accordion.Item eventKey="0">
									<Accordion.Header className="card border-0 mb-1">
										למה אני צריך אתכם?
									</Accordion.Header>
									<Accordion.Body>
										<p>
											עם הזמן והנסיון שצברנו אנחנו מאמינים
											שנוכל להפוך לכם את החוויה כיפית
											ופשוטה.
											<br />
											לחסוך כסף כל אחד יכול, אבל לחסוך זמן
											ששווה הרבה כסף זה כבר משהו אחר.
											<br />
											אנחנו לא מדברים על כל ההכנה שתקבלו
											עוד לפני שעליתם למטוס. אנחנו ההשקעה
											ששווה את המחיר!
											<br />
											מבחינתנו המטרה היא שאתם תנחתו ותבואו
											הכי מוכנים לחוויה! תרימו טלפון מה יש
											לכם להפסיד?
										</p>
									</Accordion.Body>
								</Accordion.Item>

								{/* Accordion Item */}
								<Accordion.Item eventKey="1">
									<Accordion.Header className="card border-0 mb-1">
										מה התהליך כולל?
									</Accordion.Header>
									<Accordion.Body>
										<p>
                                        תהליך של ליווי הכוונה, נעשה שיחת היכרות, ומיד אחריה נתחיל בהגשת הויזה. נהיה זמינים עבורכם 24/7 לכל השאלות, נעזור לכם במציאת טיסות ופוליסת ביטוח. וכשתנחתו נתחיל יחד איתכם בכל ההתאקלמות הראשונית, נפתח חשבון בנק, חבילת סלולר ונעזור לכם למצוא רכב שמתאים לכם.
                                        <br />
                                        אם תהיו מעוניינים לחפש עבודה, נייעץ לכם בכתיבת קורות חיים, נכוון לכרטיסי עבודה המתאימים וגם נעזור במציאת עבודה.
                                        <br />
                                        אנחנו פה כדי לעשות לכם נחיתה רכה ביבשת המטורפת הזאת!
                                        </p>
									</Accordion.Body>
								</Accordion.Item>

								{/* Accordion Item */}
								<Accordion.Item eventKey="2">
									<Accordion.Header className="card border-0 mb-1">
										אם אני צריך לטייל מה כדאי לי לעשות?
									</Accordion.Header>
									<Accordion.Body>
										<p>
                                        למטיילים שבנינו ההמלצה הכי חשובה היא לחקור את הסביבה שבה מתחילים , לבוא מוכנים עם כסף להשקעה ברכב כי לרוב יהיה לכם בו הכל , אם זה רכב 4x4 או ואן. להבין איך עובדים כאן הקאמפסייט ולהכין את עצמך לטיולים במקומות מטורפים! הדבר הכי חשוב באוסטרליה זה הטיולים לא משנה מה המטרה שלך בסוף אתה לא תפספס טיול טוב, וכאן יש המון!
                                        </p>
									</Accordion.Body>
								</Accordion.Item>

								{/* Accordion Item */}
								<Accordion.Item eventKey="3">
									<Accordion.Header className="card border-0 mb-1">
										האם מומלץ להגיע לבד או עם פרטנר/ית?
									</Accordion.Header>
									<Accordion.Body>
										<p>
                                        כמו לכל דבר יש יתרונות וחסרונות.
                                        <br />
                                        פרטנר יכול להיות אוזן קשבת, לעבור איתך חוויות שאף פעם לא חוויתם, מקומות שלא חשבתם שתהיו.
                                        <br />
                                        להיות עם שותף או בן/בת זוג יכול לאזן ולהוסיף, לפעמים גם זה להפך, אתה מתרגל לחיות עם עוד בן אדם ולהיות 24/7 אחד בשביל השני, עם הזמן לפעמים ההחלטות משתנות.
                                        <br />
                                        לא נוכל לקבל את ההחלטה בשבילך, תסמכו על עצמכם ועל האינטואיציות שלכם.
                                        </p>
									</Accordion.Body>
								</Accordion.Item>

								{/* Accordion Item */}
								<Accordion.Item eventKey="4">
									<Accordion.Header className="card border-0 mb-1">
										עם כמה כסף מומלץ להגיע לאוסטרליה?
									</Accordion.Header>
									<Accordion.Body>
										<p>
                                        לפי דעתנו הסכום הנכון הוא בין 15-20 אלף שקל, עדיף את רובו להעביר לחשבון באוסטרליה ולהשאיר סכום ביטחון בארץ לכל מקרה.
                                        <br />
                                        הסכום ההתחלתי ישרת אתכם לקניית רכב בעיקר ולחודש הראשון, זה במקרה ואתם מתכננים לעבוד.
                                        <br />
                                        המון מטיילים עובדים ומקבלים מגורים בחינם, ככה בעצם חוסכים הוצאות על לינה ואוכל.
                                        </p>
									</Accordion.Body>
								</Accordion.Item>

								{/* Accordion Item */}
								<Accordion.Item eventKey="5">
									<Accordion.Header className="card border-0 mb-1">
										איזה טיסה הכי עדיפה?
									</Accordion.Header>
									<Accordion.Body>
										<p>
                                        דעתו של כל אחד שונה במקרה הזה, יש אנשים שלא יכולים לחכות וכבר רוצים להתחיל את החוויה, ויש אנשים שינצלו את הטיסה לעשות עצירה ולטייל.
                                        <br />
                                        לנצל את העצירה לשהות של כמה ימים יכולה להיות הזדמנות טובה, הרי את הכרטיס טיסה כבר שילמת מה שנשאר זה לינה ואוכל.
                                        </p>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>
					</div>

					<div className="col-12 col-md-5 col-lg-6">
						{/* FAQ Thumbnail */}
						<div className="faq-content">
							<h2 className="mb-5">צרו קשר</h2>
							<ContactForm />
						</div>
						
						
					</div>
				</div>
			</div>
		</div>
	);
}
