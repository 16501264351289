export default function ContactForm() {
    return(
        <div className="contact-form">
            <form onSubmit={e => { e.preventDefault(); }} >
                <form className="row" 
                    action="https://script.google.com/macros/s/AKfycbx8PH24naJOS2HWkm8zGXvgtxjVU4JTi7eqr3hSzQ/exec"
                    method="POST"
                >
                    {/* Form Control */}
                    <div className="col-12 col-lg-6">
                        <label className="form-label" htmlFor="name">שם מלא:</label>
                        <input className="form-control mb-30" id="name" type="text" placeholder="" name="name" required />
                    </div>

                    {/* Form Control */}
                    <div className="col-12 col-lg-6">
                        <label className="form-label" htmlFor="email">טלפון:</label>
                        <input className="form-control mb-30" id="email" type="tel" placeholder="" name="phone" required />
                    </div>

                    {/* Form Control */}
                    

                    {/* Form Control */}
                    <div className="col-12">
                        <label className="form-label" htmlFor="message">הודעה:</label>
                        <textarea className="form-control mb-30" id="message" name="message" placeholder="" defaultValue={""} style={{height: "70px"}} />
                    </div>


                    <div className="col-12 mb-4" >

                        <input type="radio" class="btn-check" name="plan" id="option1" value="visa" autocomplete="off" />
                        <label class="btn btn-secondary contact-btn " for="option1">ויזת חופשה ועבודה</label>

                        <input type="radio" class="btn-check" name="plan" id="option2" value="full" autocomplete="off" />
                        <label class="btn btn-secondary contact-btn " for="option2">תהליך ליווי לאוסטרליה</label>

                        <input type="radio" class="btn-check" name="plan" id="option3" value="tourist" autocomplete="off" />
                        <label class="btn btn-secondary contact-btn " for="option3">ויזת תייר</label>


                    </div>

                        
                    
                    {/* Form Control */}
                    <div className="col-12 ">
                        <button className="btn w-25 " style={{backgroundColor: "#212529", color: "#fff" }} type="submit">צרו קשר</button>
                    </div>
                </form>
            </form>
        </div>
    )
}