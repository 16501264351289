import { useState } from "react"
import { Link } from "react-router-dom"
import Navbar from "react-bootstrap/Navbar"
import SaasboxNav from "./Nav"
import useStickyHeader from "./StickyHeader"

export default function Header(props) {
	const { headerStyle } = props

	let [check] = useState(true)
	const sticky = useStickyHeader(10)
	const stickyClass = `${sticky && check ? "sticky" : ""}`

	return (
		<header
			className={`header-area ${headerStyle} ${stickyClass}`}
			dir='ltr'
		>
			<Navbar expand='lg'>
				<div className='container d-flex justify-content-between'>
					{/* Navbar Brand */}
					<Link
						className='navbar-brand '
						to='/'
					>
						{/* <img src={`${process.env.PUBLIC_URL}/${brandLogo}`} alt="Saasbox" /> */}
						<h2
							style={{
								color: "#495057",
								marginBottom: "auto",
								display: "inline-block"
							}}
						>
							Easy Landing Australia
						</h2>
					</Link>
					<div class='d-sm-none'>
						<a href="https://www.instagram.com/easy_landing_australia/">
							<i
								className='bi bi-instagram'
								style={{
									fontSize: "1.2rem",
									color: "#343A40",
                                    marginRight:"0.75rem"
									
								}}
							></i>
						</a>

						<a href="https://wa.me/61468917354?text=">
							<i
								className='bi bi-whatsapp'
								style={{
									fontSize: "1.2rem",
									color: "#343A40",
								}}
							></i>
						</a>
					</div>

					{/* Navbar Toggler */}
					{/* <Navbar.Toggle
						className='navbar-toggler'
						aria-controls='saasboxNav'
					>
						<i
							className='bi bi-list'
							style={{
								fontSize: "1.4rem",
								color: "#343A40",
							}}
						></i>
					</Navbar.Toggle> */}

					<Navbar.Collapse id='saasboxNav'>
						{/* Nav */}

						<SaasboxNav />
					</Navbar.Collapse>
				</div>
			</Navbar>
		</header>
	)
}
