import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import SectionHeading from "../../global/HeadingOne";
import Container from 'react-bootstrap/Container';
import visaImg from "../../../assets/images/visa.jpg"
import flightImg from "../../../assets/images/flight.jpg"
import carImg from "../../../assets/images/car.jpg"
import workImg from "../../../assets/images/work.jpg"

const tabData = [
    {   
        "id": 1,
        "tabKey": "tab1",
        "tabTitle": "שלב 1",
        "tabText": ["", 
        "<h2 class='mb-3'>הוצאת ויזה</h2>", 
        "<p>איסוף המסמכים לבקשה ושליחתם למתרגמת שלנו. זמן התירגום לוקח עד שלושה ימי עסקים.<br /><br /> *התשלום עבור התירגום ובקשת הויזה אינו כלול בתהליך הליווי. </p>"
        ],
        tabImage: visaImg
    },
    {
        "id": 2,
        "tabKey": "tab2",
        "tabTitle": "שלב 2",
        "tabText": ["", 
        "<h2 class='mb-3'>מציאת טיסות וביטוח</h2>", 
        "<p>תקבלו מאיתנו השוואה של כל הביטוחים השונים, בנוסף נקודות שחשוב לזכור לפני בחירת פוליסת ביטוח. עבור הטיסות נבנה את המסלול שמתאים לציפיות ולמחירים שאתם מחפשים כמובן בהתחשבות בתאריך וביעד.</p>"
        ],
        "tabImage": flightImg
    },
    {
        "id": 3,
        "tabKey": "tab3",
        "tabTitle": "שלב 3",
        "tabText": ["", 
        "<h2 class='mb-3'>בנק, טלפון ורכב</h2>", 
        "<p>כל הדברים הבסיסיים שיש לטפל עם הנחיתה שלכם באוסטרליה. <br /> ניתן לכם את כל הטיפים החשובים שכדאי שתדעו על מנת להימנע מהוצאות מיותרות או בזבוזי זמן. <br /> אתם תהיו ערניים להכל!</p>"
   
        ],
        "tabImage": carImg
    },
    {
        "id": 4,
        "tabKey": "tab4",
        "tabTitle": "שלב 4",
        "tabText": ["", 
        "<h2 class='mb-3'>עבודה</h2>", 
        "<p>בניית קורות חיים כמו שנדרש באוסטרליה, הכוונה לכרטיסי עבודה עבור המקצוע שלכם. <br /> בנוסף תקבלו את כל הטיפים וההדרכה הנכונה למציאת עבודה באוסטרליה. </p>"
        
        ],
        "tabImage": workImg
    }
]

export default function SaasboxTab() {
    const tabItems = tabData.map((elem, index) => (
        <Tab key={index} eventKey={elem.tabKey} title={elem.tabTitle} >
            <div className="row align-items-center justify-content-center ">
                {/* Tab Text */}
                <div className="col-12 col-sm-9 col-md-6">
                    <div className="tab--text mt-3 mt-lg-3">
                        {elem.tabText.map((items, index) => {
                            return(
                                <div key={index} dangerouslySetInnerHTML={{__html: items}} />
                            )
                        })}
                    </div>
                </div>

                {/* Tab Thumb */}
                <div className="col-12 col-sm-9 col-md-6">
                    <div className="tab-thumb mt-5 ms-lg-5">
                        <img src={elem.tabImage} alt={elem.tabTitle} className="rounded-3" />
                    </div>
                </div>
            </div>
        </Tab>
    ))

    return(
        <div className="saasbox-tab-area  py-5" id="our-project" >
            {/* Section Heading */}
            <SectionHeading
                subtitle="המטרה שלנו היא ללוות אתכם כדי שתגיעו לאוסטרליה הכי מוכנים !"
                heading="Our Project"
            />

            <Container >
                {/* Tab */}
                <div className="tab--area text">
                    <Tabs defaultActiveKey="tab1" id="saasboxTab">
                        {tabItems}
                    </Tabs>
                </div>
            </Container>
        </div>
    )
}