import SectionHeading from "../../global/HeadingOne";
import SinglePricePlanThree from "./SinglePricePlanThree";
// import PricingModal from './PricingModal'

export default function PriceTableThree() {
    return(
        <div className="saasbox-pricing-plan-area pt-120 pb-120" id="pricing">
            {/* Section Heading */}
            <SectionHeading 
                subtitle="" 
                heading="Our Plans" 
                para="" 
            />

            <div className="container">
                <div className="row justify-content-center g-4 g-lg-5">
                    
                    {/* Single Price Table */}
                    <SinglePricePlanThree 
                        activePlan="" 
                        planTitle="ויזת חופשה ועבודה" 
                        price="249₪" 
                        priceText="" 
                        pricingDesc={[
                            {text: "איסוף מסמכים", availability: "yes"},
                            {text: "תרגום", availability: "yes"},
                            {text: "הגשת הבקשה", availability: "yes"},

                        ]}
                        btnStyle="btn-info" 
                        inputID="option1" 
                        btnText="בחירה" 
                        backgroundImage="" 
                    />
                    
                    {/* Single Price Table */}
                    <SinglePricePlanThree 
                        activePlan="active" 
                        planTitle="תהליך ליווי לאוסטרליה" 
                        price="999₪" 
                        priceText="" 
                        pricingDesc={[
                            {text: "הוצאת ויזה", availability: "yes"},
                            {text: "מציאת טיסות וביטוח", availability: "yes"},
                            {text: "ליווי בפתיחת חשבון", availability: "yes"},
                            {text: "יעוץ בקניית רכב", availability: "yes"},
                            {text: "ליווי ברכישת חבילת סלולר", availability: "yes"},
                            {text: 'כתיבת קו"ח', availability: "yes"},
                            {text: "הכוונה לכרטיסי עבודה", availability: "yes"},
                            {text: "עזרה במציאת עבודה", availability: "yes"},
                        ]}
                        btnStyle="btn-warning" 
                        inputID="option2" 
                        btnText="בחירה" 
                        backgroundImage="assets/img/core-img/3.png" 
                    />
                      <SinglePricePlanThree 
                        activePlan="" 
                        planTitle=" ויזת תייר" 
                        price="199₪"
                        priceText="" 
                        p pricingDesc={[
                            {text: "איסוף מסמכים", availability: "yes"},
                            {text: "תרגום", availability: "yes"},
                            {text: "הגשת הבקשה", availability: "yes"},

                        ]}
                        btnStyle="btn-info" 
                        inputID="option3" 
                        btnText="בחירה" 
                        backgroundImage="" 
                    />
                    {/* <PricingModal /> */}
                </div>
            </div>
        </div>
    )
}