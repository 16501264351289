import { Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

// All CSS Import
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/all-css-libraries.css";
import "../node_modules/react-modal-video/scss/modal-video.scss";
import "./assets/scss/style.scss";

// Home Page
import DemoPage from "./pages/Index";

import NotFound from "./pages/NotFound";

export default function App() {
	return (
		<div className="App">
			<Routes>
				<Route path="/" index element={<DemoPage />} />
				<Route path="/not-found" element={<NotFound />} />
				<Route path="*" element={<Navigate to="/not-found" />} />
			</Routes>

			<ScrollToTop id="scrollTopButton" color="white" smooth top={200} />
		</div>
	);
}
