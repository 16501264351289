import Header from "../components/header/Header";
// import Footer from "../components/footer/Footer";
import HeroThree from "../components/sections/home/HeroThree";
import Divider from "../components/global/Divider";
import FAQOne from "../components/sections/contact-us/FaqOne";
import PriceTableThree from "../components/sections/pricing/PricingThree";
import SEO from "../components/global/SEO";
import CoolFacts from "../components/sections/cool-facts/CoolFacts";
import SaasboxTab from "../components/sections/our-project/Tab";

export default function SaasLanding() {
	return (
		<div className="saas-landing-wrap" dir="rtl">
			<SEO title="Easy Landing Australia" />

			<div class="d-sm-none text-light bg-dark text-center px-3 py-1">
				למשתמשי האתר בלבד 10% הנחה על תוכנית הליווי המלאה שלנו.
			</div>

			<Header
				brandLogo="assets/img/core-img/logo-white.png"
				headerStyle=""
				buttonText="Log In"
				buttonColor="btn-warning"
			/>


			<div class="d-sm-none">
				<Divider />
				<Divider />
				<CoolFacts />
				<Divider />
				<Divider />
				<Divider />
			</div>


			<HeroThree />


			<div className="d-none d-sm-block">
			<Divider />
				<CoolFacts />
			<Divider />

			</div>


			<SaasboxTab />

			<PriceTableThree />


			<FAQOne
				heading="שאלות נפוצות"
				image="assets/img/illustrator/3.png"
			/>

			<Divider />

			{/* <Footer 
                footerLogo="assets/img/core-img/logo-white.png"
                footerStyle="footer-2" 
            /> */}
		</div>
	);
}
